// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-list-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-backup-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/src/pages/index-backup.js" /* webpackChunkName: "component---src-pages-index-backup-js" */),
  "component---src-pages-index-js": () => import("/mnt/c/Users/jerem/Dropbox/code/jbernier-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

